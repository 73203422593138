import request from '@/utils/request'
// 查询监测主题
export function showMonitorSubject (data) {
  return request({
    url: '/cus/showMonitorSubject',
    method: 'POST',
    data
  })
}
export function fastAddMS (data) {
  return request({
    url: '/cus/fastAddMS',
    method: 'POST',
    data
  })
}
// 监测主题创建
export function addMonitorSubject (data) {
  return request({
    url: '/cus/addMonitorSubject',
    method: 'POST',
    data
  })
}
// 监测主题删除
export function delectSubject (data) {
  return request({
    url: '/cus/deleteMonitorSubject',
    method: 'POST',
    data
  })
}
// 监测主题修改
export function updateMonitorSubject (data) {
  return request({
    url: '/cus/updateMonitorSubject',
    method: 'POST',
    data
  })
}
// 监测主题修改回显数据
export function selMenu (data) {
  return request({
    url: '/cus/selMenu',
    method: 'POST',
    data
  })
}
// 监测分类修改回显数据
export function classSave (data) {
  return request({
    url: '/cus/addMonitorSubject',
    method: 'POST',
    data
  })
}
// 分类接口
export function Updateclass (data) {
  return request({
    url: '/cus/updateMonitorSubject',
    method: 'POST',
    data
  })
}
export function delectSort (data) {
  return request({
    url: '/cus/delectSort',
    method: 'POST',
    data
  })
}
// 添加排除词到分类
export function addToSort (data) {
  return request({
    url: '/cus/addToSortExclusion',
    method: 'POST',
    data
  })
}
export function testRule (data) {
  return request({
    url: '/cus/testRule',
    method: 'POST',
    data
  })
}
export function selectExuldedSites (data) {
  return request({
    url: '/cus/selectExuldedSites',
    method: 'POST',
    data
  })
}
export function listAllSite (data) {
  return request({
    url: '/cus/listAllSite',
    method: 'POST',
    data
  })
}
export function addExcludeSite (data) {
  return request({
    url: '/cus/addExcludeSite',
    method: 'POST',
    data
  })
}
export function delExcludeSite (data) {
  return request({
    url: '/cus/delExcludeSite',
    method: 'DELETE',
    data
  })
}
