import request from '@/utils/request'
export function saveTemplate (data) {
  return request({
    url: '/diy/saveTem',
    method: 'POST',
    data
  })
}
export function listTemplates (data) {
  return request({
    url: '/diy/listTem',
    method: 'POST',
    data
  })
}
export function listTemplatesForC (data) {
  return request({
    url: '/diy/listTemForC',
    method: 'POST',
    data
  })
}
export function switchTemEnable (data) {
  return request({
    url: '/diy/switchTemEnable',
    method: 'POST',
    data
  })
}
export function getDetail (data) {
  return request({
    url: '/diy/getDetail',
    method: 'POST',
    data
  })
}
export function delTem (data) {
  return request({
    url: '/diy/delTem',
    method: 'POST',
    data
  })
}
export function updateTemplate (data) {
  return request({
    url: '/diy/updateTem',
    method: 'POST',
    data
  })
}
export function createNow (data) {
  return request({
    url: '/diy/createNow',
    method: 'POST',
    data
  })
}
export function getAllEmailReceiver (data) {
  return request({
    url: '/diy/getAllEmailReceiver',
    method: 'POST',
    data
  })
}
export function updateReceiver (data) {
  return request({
    url: '/diy/updateReceiver',
    method: 'POST',
    data
  })
}
export function getSysTemList (data) {
  return request({
    url: '/diy/getSysTemList',
    method: 'POST',
    data
  })
}
export function createWordByMs (data) {
  return request({
    url: '/diy/createWordByMs',
    method: 'POST',
    data
  })
}
export function createWordByFf (data) {
  return request({
    url: '/diy/createWordByFf',
    method: 'POST',
    data
  })
}
